import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useReducedCaptions, useGetSummaryLongForm } from '../../ai/hook';
import { useEditTask } from '../../task/hooks/useEditTask';

const MAX_INPUT_LENGTH = 30000; // Ensure this constant is defined or passed as a parameter

export const useSummaryGenerator = ({
  task,
  coverType,
  setInProgress,
  captions,
}) => {
  const [summary, setSummary] = useState(task?.cover?.summary);
  const [showSummary, setShowSummary] = useState(false);

  const { formatMessage } = useIntl();
  const getReducedCaptions = useReducedCaptions();
  const { getSummaryLongForm } = useGetSummaryLongForm();
  const { editTask } = useEditTask();

  const saveSummaryToTask = async (subtitles, summary) => {
    const params = {
      variables: {
        taskId: task?.id,
        taskData: {
          cover: {
            id: task?.cover?.id,
            raw: subtitles?.substring(0, 10000),
            source: task?.cover?.source,
            type: coverType,
            summary: summary,
          },
          source: summary,
          ...(task?.tags && {
            tagIds: task?.tags?.map((tag) => tag?.id),
          }),
        },
      },
    };
    await editTask(params);
  };

  const handleGenerateSummary = async (subtitles) => {
    setInProgress({
      message: formatMessage({ id: 'progress_subtitle_generation_message' }),
      key: 'editTaskLoading',
    });

    if (!!subtitles) {
      const title = task?.title?.substring(0, 70);
      let generatedSummary;
      if (subtitles.length >= MAX_INPUT_LENGTH) {
        // Make it shorter
        generatedSummary = await getReducedCaptions(subtitles, title);
      } else {
        generatedSummary = await getSummaryLongForm(subtitles, title);
      }
      if (task?.id) {
        await saveSummaryToTask(subtitles, generatedSummary);
      }
      setSummary(generatedSummary);
      setInProgress({ key: 'editTaskLoading' });
      return generatedSummary;
    }
  };

  const handleSummaryClick = async () => {
    if (!summary) {
      await handleGenerateSummary(captions);
    }
    setShowSummary((prev) => !prev);
  };

  return {
    summary,
    showSummary,
    handleGenerateSummary,
    handleSummaryClick,
  };
};