import { useCallback } from "react";
import { useGetVideoListByString } from "../../youtube/hooks";
import { useGetCommonAi } from "./";

export const useGetVideoSet = () => {
    const { getVideoList } = useGetVideoListByString();
    const { getCommonAi } = useGetCommonAi();

    const getVideoSet = useCallback(
        async (queryString, goal, channelId, userRequestSearch) => {
            let string = "";
            let items = [];
            if (channelId) {
                // search in channel
                const indexSemi = queryString.indexOf(":");
                string = userRequestSearch || queryString?.substring(0, indexSemi);
       

                const { data } = await getVideoList({
                    variables: {
                        q: string,
                        maxResults: 40,
                        notSorted: true,
                        channelId: channelId || '',
                    },
                });

                items = data?.getVideoList?.items;

            } else {
                //it is automate search
                if (!userRequestSearch) {
                    const requestAI = await getCommonAi(queryString + ' ' + goal, "searchRequest");
                    string = requestAI || queryString;
                }

                const { data } = await getVideoList({
                    variables: {
                        q: userRequestSearch || string,
                        maxResults: 40,
                    },
                });
                items = data?.getVideoList?.items;
            }

            if (!!items?.length) {
                let itemsClone = items.map((item) => {
                    const { id, title, description, publishTime, thumbnail } = item;

                    return {
                        source: `https://www.youtube.com/embed/${id}`,
                        title: title,
                        summary: description,
                        thumbnail: thumbnail,
                        publishTime: publishTime,
                        type: 'YOUTUBE',
                        videoId: id,
                    }
                })
                return itemsClone;
            } else {
                return null;
            }
        },
        [getVideoList]
    );

    return { getVideoSet };
};
