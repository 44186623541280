import './scss/index.scss';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { BgProfileComponent } from '../../headers/BgProfile.component';
import { SPEC_SUBJECTSID } from "../../constant";

export const LandingPromoComponent = ({ handleScrollClick, trackClick, ...other }) => {
    // const [spec, setSpec] = useState(SPEC_SUBJECTSID[getRandomInteger(0,6)]);
    const [spec, setSpec] = useState(SPEC_SUBJECTSID[3]);
    const { formatMessage } = useIntl();
    return (
        <>
            <div className=' wrapper_hero '>
                <div className='wrapper_hero-text quote'>
                    <div className='wrapper_supertitle '>
                        <h1 className='supertitle'>AI Courses That Evolve With Your Students</h1>
                        <p>
                            Create adaptive courses in minutes and watch them improve based on real-time student engagement
                            {/* <br />Play with it, ask something: */}
                        </p>
                    </div>
                </div>
            </div>

            <BgProfileComponent specialization={spec} />
          
        </>
    );
};
