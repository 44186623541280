import React, { useState, useEffect } from 'react';
import { Input } from 'antd';
import { VideoSet, VideoPlayer } from '../VideoIframe';
import { useIntl } from 'react-intl';

import { LoaderComponent } from '../../common';
import { useGetVideoSet } from "../../ai";
import { noop } from 'lodash';
const { Search } = Input;

export const YouTubePublicTab = ({ userIsAllowGenerateContent,
    task,
    user,
    event,
    setCover = noop,
    inProgress,
    setInProgress = noop,
    ...other }) => {
    const { formatMessage } = useIntl();

    const [videoSet, setVideoSet] = useState([])
    const [videoSrc, setVideoSrc] = useState(null)
    const [videoId, setVideoId] = useState(null)

    const { getVideoSet } = useGetVideoSet();
    const sourceObject = event?.galleriesInEvent;
    const channelId = sourceObject?.find(el => el.id === 'yt_channel')?.src || event?.program || '';

    // const [captions, setCaptions] = useState(null)
    // const [summary, setSummary] = useState(null)
    // const [showSummary, setShowSummary] = useState(false)

    const findVideosToCover = async (request) => {
        setInProgress(formatMessage({ id: 'loader_video' }))

        let videoSet =
            await getVideoSet(
                task?.title?.trim(),
                event?.title?.substring(0, 150).trim(),
                channelId,
                request
            );
        if (!!videoSet?.length) {
            setVideoSet(videoSet);
            handleChangeVideo(videoSet[0])
        }
        setInProgress('')
    };
    const getCover = (video) => {
        if (video) {
            return {
                type: video.type,
                source: video.source,
                id: task.cover?.id,
                timestamps: [],
                title: video.title,
            }
        }
    }

    const handleChangeVideo = (video) => {
        if (video) {
            setVideoId(video.videoId);
            setVideoSrc(video.source);
            let coverData = getCover(video);
            setCover(coverData);

        }
    };

    useEffect(() => {
        const lessThan20Tasks = !!event && event?.tasks?.length < 21;
        if (lessThan20Tasks && !!event && userIsAllowGenerateContent) {
            if (!videoSet?.length) {
                (async () => {
                    if (!inProgress) {
                        await findVideosToCover()
                    }
                })();
            }
        }
    }, [task?.isAutogenerated, event?.tasks?.length]);


    return (
        <div className="youtube-public-tab">
            <Search
                placeholder={formatMessage({ id: 'search_string' })}
                onSearch={(e) => {
                    if (!inProgress) {
                        findVideosToCover(e)
                    }
                }}
                enterButton={false}
            />

            <VideoSet
                videoSet={videoSet}
                isAllow={userIsAllowGenerateContent}
                handleChangeVideo={handleChangeVideo}
                {...other} />
            {inProgress && <LoaderComponent type="inline" />}

            <VideoPlayer
                {...other}
                task={task}
                user={user}
                videoId={videoId}
                videoSrc={videoSrc}
                videoSet={videoSet}
                handleChangeVideo={handleChangeVideo}
                {...other}
            />
        </div>
    );
};