import { useState, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useGetChunksRaw, useGetChunks, useGetImagesDalle, useGenerateAudioChunks } from './';
import { noop } from 'lodash';

const MAX = 30000;

export const useGetAiVideo = (task, setInProgress = noop, errorMessage) => {
    const { getChunksRaw } = useGetChunksRaw();
    const { getChunks } = useGetChunks();
    const [error, setError] = useState();

    const { generateAudioForChunks } = useGenerateAudioChunks();
    const { getAiImage } = useGetImagesDalle();
    const { formatMessage } = useIntl();
    const saveCoverToTask = (updatedChunksWithImages) => {
        if (updatedChunksWithImages) {

            let timeStampsList = [];

            for (const [i, chunk] of updatedChunksWithImages.entries()) {
                let description = {
                    description: chunk.description,
                    main_idea: chunk.main_idea,
                    ill: chunk.ill,
                    images: chunk.images,
                    audio: chunk.audio,
                    example: chunk.example,
                    code: chunk.code,
                    diagram: chunk.diagram,
                };
                timeStampsList.push({
                    title: chunk.title,
                    description: JSON.stringify(description),
                    timestamp: `${i}`,
                    id: chunk.id
                });
            }

            return {
                cover: {
                    type: "VIDEO",
                    source: "",
                    raw: "",
                    // id: task?.cover?.id,
                    timestamps: [...timeStampsList]
                },
            };
        }
    }
    const generateMarkdown = (chunks) => {
        return chunks.map(chunk => {
            const { title, description, ill, diagram, example } = chunk;
            return `## ${title}\n\n${description}\n${ill ? `<div class='image-snippet'><img src="${ill}" alt="${title.replace(/[^a-zA-Z ]/g, "")}" /></div>` : ''}\n${diagram ? `mermaid\n${diagram}\nmermaid end` : ''}\n\n${example || ''}`;
        }).join('\n\n');
    };
    const generateImagesForChunks = async (updatedChunks) => {
        setInProgress({
            message: formatMessage({ id: 'progress_images' }),
            key: 'progress_images'
        })
        const imagePromises = updatedChunks.map(async (chunk) => {
            if (chunk.ill) {
                const ill = await getAiImage(`Create a minimalistic 3D visualisation with dark red, blue, green for this topic: ${chunk?.ill} ${chunk?.title} `);
                chunk.ill = ill;
            }
            return chunk;
        })
        try {
            const updatedChunksImages = await Promise?.all(imagePromises);
            setInProgress({ key: 'progress_images' })
            return updatedChunksImages;
        }
        catch (error) {
            setInProgress({ key: 'progress_images' })
            console.log(error.message, 'error.message')
            // errorMessage(error.message);

        }
    }
    const parseDescriptionToChunksRaw = async (steptitle, source, curriculum, goal) => {
        setInProgress({
            message: formatMessage({ id: 'progress_chunks' }),
            key: 'progress_chunks'
        });
        let cleanedDescription = source?.substring(0, MAX)?.replaceAll("*", '')?.replaceAll("#", "") || '';

        // conditionally by type
        let json = await getChunksRaw(steptitle, cleanedDescription, curriculum, goal);
        setInProgress({ key: 'progress_chunks' });
        if (!json) {
            errorMessage('Error fetching chunks');
        } else {
            json = json?.replace('```json', '')?.replace(/```/g, '');
            if (json) {
                return JSON.parse(json).parts;
            }
        }
    };
    const parseDescriptionToChunks = async (contentForVideo) => {
        setInProgress({
            message: formatMessage({ id: 'progress_chunks' }),
            key: 'progress_chunks'
        });
        let cleanedDescription = contentForVideo?.substring(0, MAX)?.replaceAll("*", '')?.replaceAll("#", "");

        let json = await getChunks(cleanedDescription);
        setInProgress({ key: 'progress_chunks' });

        if (!json) {
            setError(error);
        } else {
            json = json?.replace('```json', '')?.replace(/```/g, '')
            if (json) {
                return JSON.parse(json).parts;
            }
        }
    };
    const getGenerateVideoAndIntro = async (task, event, voiceMode) => {
        let steptitle = task?.title?.replace(/#/g, '')?.replace('\u0002', "")?.replace('\\', "");
        let source = task?.source || task?.summary;
        const curriculum = event?.tasks?.map(t => t?.title?.substring(0, 55)).join(', ') || '';
        let goal = event?.title?.substring(0, 150) || '';

        let updatedChunksWithImages = [];
        try {
            let data = await parseDescriptionToChunksRaw(steptitle, source, curriculum, goal);
            if (data) {
                setInProgress({
                    message: formatMessage({ id: 'progress_audio' }),
                    key: 'progress_audio'
                });
                let updatedChunks = await generateAudioForChunks(data, voiceMode, setError, formatMessage);
                setInProgress({ key: 'progress_audio' });
                updatedChunksWithImages = await generateImagesForChunks(updatedChunks);
                if (updatedChunksWithImages) {

                    let coverData = saveCoverToTask(updatedChunksWithImages);
                    let markdown = generateMarkdown(updatedChunksWithImages);
                    return { coverData, markdown, updatedChunksWithImages };
                }
            }

        } catch (error) {
            console.log(error, 'error.message')
            // errorMessage(error);
        }
    };
    const getGenerateVideo = async (content, voiceMode) => {
        let updatedChunksWithImages = [];
        try {
            let data = await parseDescriptionToChunks(content);
            if (data) {
                setInProgress({
                    message: formatMessage({ id: 'progress_audio' }),
                    key: 'progress_audio'
                });
                let updatedChunks = await generateAudioForChunks(data, voiceMode, setError, formatMessage);
                setInProgress({ key: 'progress_audio' });
                updatedChunksWithImages = await generateImagesForChunks(updatedChunks);
                if (updatedChunksWithImages) {
                    let coverData = saveCoverToTask(updatedChunksWithImages);

                    return { coverData, updatedChunksWithImages };
                }
            }

        } catch (error) {
            console.log(error.message, 'error.message')
            // errorMessage(error.message);
        }
    };

    return { getGenerateVideoAndIntro, getGenerateVideo, error };
};