import React, { useEffect, useState, useRef, useMemo } from 'react';
import { cloneDeep } from "lodash";
import { message, Modal } from 'antd';
import queryString from 'query-string';
import { useHistory, useLocation } from 'react-router-dom';
import { useIntl } from 'react-intl';

import { useTaskDripLogic } from './hooks/useTaskDripLogic';
import {
    useGetResult,
    useUpdateResult,
    useGetResults,
    useGetResultsByUserid
} from "../result";
import { TaskArticleComponent } from "./TaskArticle.component";
import { TaskCourseArticleComponent } from "./TaskCourseArticle.component";
import { stepParseJson, getModelFromAudioId, useTrackClick, useIsAllow, VoiceSelector, useConditionalVoiceMode } from '../common';
import { useEditTask } from "./hooks/useEditTask";
import { useEditQuestion } from "./hooks/useEditQuestion";
import { useProgress } from '../common/hooks/useProgress';
import { useNextStepAi } from '../ai/hook/useNextStepAi';

import {
    useGetResultAi,
    useGetSummaryLongForm,
    useReducedCaptions,
    useGetAiVideo,
} from "../ai";
import { useAiChatResponse } from "../ai/graphQL/useAiChatResponse";
import { useIsPermit } from '../roles/hook/useCheckPermission';
let actualQuestions;
let NEW_CONTENT = '';
let isQuestionProgress = false;
let descriptionInProgress = false;

export const TaskArticleContainer = (props) => {
    const { task, user, updateTask, event, localePrefix, workspace, isFirst, ...other } = props;
    const { formatMessage } = useIntl();
    const { tags, questions, creatorId } = task || {};
    const { updateResult } = useUpdateResult();
    actualQuestions = questions;

    const questionStarted = useRef(false);
    const { resultsByUserid, getResultsByUserid } = useGetResultsByUserid();
    const trackClick = useTrackClick();
    const history = useHistory();
    const { search } = useLocation();
    let values = queryString.parse(search);
    const { result, getResult } = useGetResult();
    const { results, getResults } = useGetResults();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const { inProgress, handleChangeProgress } = useProgress();
    const { editQuestion } = useEditQuestion();
    const { getNextStep } = useNextStepAi();

    const [voiceMode, setVoiceMode] = useState();
    const curriculum = useMemo(() => {
        const titles = event?.tasks?.map(t => t?.title?.substring(0, 55)) || [];
        return titles.join(', ');
    }, [event?.tasks]);

    const [highlightedRequest, setHighlightedRequest] = useState();
    const [highlightedNode, setHighlightedNode] = useState();
    const [taskContent, setTaskContent] = useState(task?.description || '');
    const [resultState, setResultState] = useState(result);
    const [inProgressHighlight, setInProgressHighlight] = useState();
    const [taskNotLogged, setTaskNotLogged] = useState(task);
    const [stepId, setStepId] = useState(values?.step);

    const { editTask } = useEditTask();
    const { getResultAi } = useGetResultAi();
    const { getChatResponse } = useAiChatResponse();
    const { getGenerateVideoAndIntro } = useGetAiVideo(task, handleChangeProgress);

    const { checkPermit } = useIsPermit()
    const editOthersCourses = checkPermit(workspace, 'editOthersCourses')
    const useIsAllowed = useIsAllow();
    const isPaid = useIsAllowed("PREMIUM");
    const userIsAllowGenerateContent = ((isFirst || isPaid || checkPermit(workspace, 'createCourses')) && user?.id === creatorId) || editOthersCourses || user?.role?.id === 1;
    const { taskResult, tagsRenew, progressCounter } = useTaskDripLogic(event, task, resultState, resultsByUserid, userIsAllowGenerateContent, [resultState]);
    const isJoinRequired = useMemo(() => {
        let result = '';
        const currentTaskWithHidden = taskResult.find(t => t?.id === task?.id);
        const currentQuestion = stepId ? currentTaskWithHidden?.questions?.find(q => q.id === stepId) : null;
        if (user?.id === creatorId || user?.role?.id === 1) {
            return result;
        }

        //check if joined
        if (event && !event?.isDraft) {
            result = !event?.isSubscribe ? 'join' : '';
        }

        if (event && !event?.isDraft) {
            result = !task?.isAvailable && !userIsAllowGenerateContent ? 'join' : '';
        }
        //check if drip feeding
        if (task?.isAvailable && currentTaskWithHidden?.isHidden) {
            result = 'drip';
        }
        //check if it is question is hidden and apply result = 'drip '
        if (task?.isAvailable && currentQuestion?.isHidden) {
            result = 'drip';
        }

        return result;

    }, [user, creatorId, stepId, resultState?.answers?.length, event, task]);

    // result
    const onUpdateResultDescription = async (newPartOfDescription) => {
        trackClick(
            'click-resultform',
            task.id,
            `https://unschooler.me${localePrefix}/tutorials/${task.id}`
        );

        let normalisedAnswers = [];
        result?.answers?.forEach((ans) => {
            normalisedAnswers.push({
                answer: ans.answer,
                questionId: ans.questionId,
                optionIds: ans.optionIds
                    ? ans.optionIds
                    : ans.options?.map((option) => option.id),
            });
        });

        if (user?.id) {
            let newResult = {
                answers: normalisedAnswers || [],
                taskId: task.id,
                description: (result?.description ? result?.description : '') + (newPartOfDescription ? newPartOfDescription : '') || '',
            }

            const { id, workspaceId } = event || {};

            await updateResult({
                variables: {
                    resultData: {
                        ...newResult,
                    },
                },
            }, { id, workspaceId });

        }
    };
    const updateResults = async () => {
        getResults({
            variables: { filter: { taskId: task?.id } },
            fetchPolicy: "network-only",
        });

        getResult({
            variables: { taskId: task?.id },
            fetchPolicy: "network-only",
        });
    };
    const handleMouseUp = () => {
        const string = window?.getSelection()?.toString();
        setHighlightedNode(window?.getSelection()?.extentNode?.wholeText);
        setHighlightedRequest(
            window?.getSelection()?.type === "Range" ? string : null
        );
    };
    const getPrompt = (prompt) => {
        if (prompt) {
            return `${prompt} Put examples in code block and terms with emoji in bold `;
        }
    }
    const onRegenerate = async () => {
            (!NEW_CONTENT && !task.description) && await handleDescriptionStart();
        (!task?.questions?.length && !isQuestionProgress) && await handleCreateQuestion(true);
    }
    const handleGenerateIntroVideo = async () => {
        handleChangeProgress({
            message: formatMessage({ id: 'progress_description_message' }),
            key: 'descriptionStart'
        })

        const resp = await getGenerateVideoAndIntro(task, event, voiceMode);
        const { coverData, markdown, error } = resp || {};
        if (error) {
            errorMessage(error)
        }

        await handleEditVideoTask(coverData, markdown);
        descriptionInProgress = false;
        handleChangeProgress({ key: 'descriptionStart' });
    }
    const handleDescriptionStart = async () => {
        let stepTitle = task?.title?.replace(/#/g, '')?.replace('\u0002', "")?.replace('\\', "");
        if (task?.cover?.source || task?.isAutogenerated) {
            try {
                const respData = await getNextStep({
                    goal: task?.title || "",
                    steptitle: stepTitle,
                    source: task.source,
                    curriculum: curriculum,
                    handleChangeProgress,
                }) || "";

                await handleEditTask(respData);
                descriptionInProgress = false;
            } catch (error) {
                errorMessage('AI did not answered, reload the page and try again');
            }

        } else {
            await handleGenerateIntroVideo()
        }
    }
    const handlePlanGenerate = async (settings) => {
        handleChangeProgress({
            message: formatMessage({ id: 'progress_plan_generate_message' }),
            key: 'planGenerate'
        })
        const resp = await getChatResponse({
            variables: {
                input: settings,
            },
        });
        const responseJSON = resp?.data
            ? JSON.parse(resp?.data?.requestChatApi)
            : null;
        handleChangeProgress({ key: 'planGenerate' })
        if (responseJSON?.choices?.[0]) {
            return responseJSON?.choices?.[0]?.message?.content?.trim();
        }
    };
    const handleCreateQuestion = async (noRedirect) => {
        handleChangeProgress({
            message: formatMessage({ id: 'progress_create_question_message' }),
            key: 'createQuestion'
        })
        isQuestionProgress = true;
        const settings = {
            args: {
                title: task?.title + '\n' + task?.source?.substring(0, 30000),
                curriculum: curriculum,
                goal: event?.title,
            },
            type: "tutorialProjectStepsLongForm",
        };

        let stepTitle = await handlePlanGenerate(settings);

        const parsedSteps = stepParseJson(stepTitle);
        if (parsedSteps?.steps) {
            let convertedQuestions = [];
            let allSteps = parsedSteps.steps;
            for (let step = 0; step < parsedSteps.steps?.length; step++) {
                let questionTitle = `## ${allSteps[step]}.`;
                let newQuestion = {
                    type: "TEXT",
                    question: '',
                    title: questionTitle,
                    order: step,
                };

                convertedQuestions.push(newQuestion);
            }

            actualQuestions = convertedQuestions;
            const { data } = await handleEditQuestions(convertedQuestions);

            handleChangeProgress({ key: 'createQuestion' })
            isQuestionProgress = false;
            if (!noRedirect) {
                const nextStepId = data.editTask?.questions?.[0]?.id;
                const searchParams = new URLSearchParams(search);
                searchParams.set('step', nextStepId.toString());
                history.push({ search: searchParams.toString() });
            }
        } else {
            handleChangeProgress({ key: 'createQuestion' })
        }
    };


    const handleCheckResultAi = async (questionId, userAnswer) => {
        handleChangeProgress({
            message: formatMessage({ id: 'progress_check_result_message' }),
            key: 'checkResultAi'
        })

        const question = task?.questions?.find((q) => q.id === questionId);
        const response = await getResultAi(question?.answer, userAnswer, formatMessage);
        if (response) {
            handleChangeProgress({
                key: 'checkResultAi'
            })
            return response;
        }
    };
    const handleEditTask = async (description, aiAnswer) => {
        setTaskContent(description);

        let tagIdsArray = [];
        task?.tags?.forEach((tag) => tagIdsArray.push(tag.id));

        if (userIsAllowGenerateContent) {
            try {
                await editTask({
                    variables: {
                        taskId: task?.id,
                        taskData: {
                            description: description,
                            tagIds: tagIdsArray,
                        },
                        isOwner: editOthersCourses,
                    },
                });
                successMessage('description');

            } catch (error) {
                errorMessage('Error editing task, try to reload the page');
            }
        } else if (user?.id) {
            await onUpdateResultDescription(aiAnswer)
        }
        setTaskNotLogged((prev) => {
            let taskLocalNew = { ...prev };
            taskLocalNew.description = description;
            return taskLocalNew;
        });
        if (updateTask) {
            await updateTask({ fetchPolicy: "network-only" });
        }
    };
    const handleEditVideoTask = async (coverData, description) => {
        let tagIdsArray = [];
        task?.tags?.forEach((tag) => tagIdsArray.push(tag.id));

        await editTask({
            variables: {
                taskId: task?.id,
                taskData: {
                    tagIds: tagIdsArray,
                    description: description,
                    ...coverData,
                },
                isOwner: editOthersCourses,
            },
        });
        successMessage('video');

        if (updateTask) {
            await updateTask({ fetchPolicy: "network-only" });
        }
    };
    const handleEditQuestions = async (convertedQuestions) => {
        let tagIdsArray = [];
        tags?.forEach((tag) => tagIdsArray.push(tag.id));

        const data = await editTask({
            variables: {
                taskId: task?.id,
                taskData: {
                    questions: convertedQuestions,
                    tagIds: tagIdsArray,
                },
                isOwner: editOthersCourses,
            },
        });

        if (updateTask) {
            await updateTask({ fetchPolicy: "network-only" });
        }
        return data;
    };
    const handleReplaceQuestion = async (newQuestion, questionId, aiAnswer) => {
        let arrayCloned = cloneDeep(actualQuestions || task?.questions);
        let question = arrayCloned.find((el) => el.id === questionId);

        Object.keys(newQuestion).forEach((key) => {
            if (key in newQuestion) {
                question[key] = newQuestion[key];
            }
        });

        if (userIsAllowGenerateContent) {
            let { data } = await editQuestion({
                variables: {
                    input: { ...question, }
                },
            });

            if (updateTask) { await updateTask({ fetchPolicy: "network-only" }); }
            successMessage('step');

        } else if (user?.id) {
            await onUpdateResultDescription(aiAnswer)
        }

        actualQuestions = [...arrayCloned];

        setTaskNotLogged((prev) => {
            let taskLocalNew = { ...prev };
            taskLocalNew.questions = [...arrayCloned];
            return taskLocalNew;
        });

    };
    const handleDuplicateQuestion = async (questionId, stepDescription, notRedirect, empty) => {
        handleChangeProgress({
            message: formatMessage({ id: 'progress_create_question_message' }),
            key: 'step'
        });
        let index = task?.questions?.findIndex((el) => el.id === questionId);
        let convertedQuestions = [...task.questions];
        let newQuestion = {
            type: "DEFAULT", //
            title: stepDescription,
            question: empty ? stepDescription : '',
            order: index + 1,
        };
        convertedQuestions?.splice(index + 1, 0, newQuestion);
        for (let i = index + 2; i < convertedQuestions?.length; i++) {
            let convertedQuestionsClone = { ...convertedQuestions[i] }
            convertedQuestionsClone.order = convertedQuestions[i]?.order + 1;
            convertedQuestions[i] = convertedQuestionsClone;
        }

        actualQuestions = convertedQuestions;
        try {
            const { data } = await handleEditQuestions(convertedQuestions);
            if (!notRedirect && data) {
                const nextStepId = data.editTask?.questions?.[index + 1]?.id;
                const searchParams = new URLSearchParams(search);
                searchParams.set('step', nextStepId.toString());
                history.push({ search: searchParams.toString() });
            }
            handleChangeProgress({ key: 'step' });
            return data.editTask?.questions[index + 1];
        } catch (error) {
            handleChangeProgress({ key: 'step' });
            errorMessage(error);
        }
        handleChangeProgress({ end: true })
    }
    const handleDeleteQuestion = async (questionId) => {
        let index = task?.questions?.findIndex((el) => el.id === questionId);

        let convertedQuestions = cloneDeep(task.questions);
        convertedQuestions.splice(index, 1);

        for (let i = index; i < convertedQuestions.length; i++) {
            convertedQuestions[i].order = convertedQuestions[i].order - 1;
        }

        actualQuestions = convertedQuestions;
        const data = await handleEditQuestions(convertedQuestions);

        if (data) {
            const nextStepId = task?.questions?.[index + 1]?.id;
            const searchParams = new URLSearchParams(search);
            searchParams.set('step', nextStepId?.toString());
            history.push({ search: searchParams?.toString() });
        }
    }
    const takeResult = async () => {
        await getResult({
            variables: { taskId: task?.id },
            fetchPolicy: "network-only",
        });
    }

    useEffect(() => {
        setStepId(values?.step)
    }, [values]);

    useEffect(() => {
        NEW_CONTENT = taskContent || '';
    }, [taskContent])

    useEffect(() => {
        setResultState(result);
    }, [result])

    //generate content
    const lessThan20Tasks = !!event && event?.tasks?.length < 21;
    const { getSafetyModel } = useConditionalVoiceMode()

    useEffect(() => {
        if (user?.defaultVoiceId) {
            (async () => {
                const model = await getModelFromAudioId(user.defaultVoiceId);
                if (!model?.model) {
                    setVoiceMode(model);
                } else {
                    const safetyModel = await getSafetyModel(user?.id, model);
                    setVoiceMode(safetyModel);
                }
                setIsModalVisible(false);
            })();
        }
    }, [user?.defaultVoiceId])

    useEffect(() => {
        if (!!event && userIsAllowGenerateContent && lessThan20Tasks) {
            if ((!task?.description && !descriptionInProgress)) {
                if (!voiceMode && !task?.isAutogenerated) {
                    setIsModalVisible(true);
                } else {
                    (async () => {
                        await onRegenerate(task?.source);
                        descriptionInProgress = true
                    })()
                }
            }
        }

    }, [task?.description, event?.id, voiceMode?.value]);

    useEffect(() => {
        setTaskContent(task?.description);
        (async () => await getResults({
            variables: { filter: { taskId: task?.id } },
            fetchPolicy: 'network-only'
        }))();
    }, [task?.id, task?.description]);

    useEffect(() => {
        if (!!user?.id) {
            (async () => await getResultsByUserid({
                variables: { filter: { userId: user?.id } },
                fetchPolicy: 'network-only'
            }))();
            (async () => await takeResult())();
        }
    }, [user?.id]);

    useEffect(() => {
        descriptionInProgress = false;
    }, []);

    const successMessage = (content) => {
        message.success(`New ${content} was saved`, 3);
    };
    const errorMessage = () => {
        message.error('Ai did not respond, reload the page', 3);
    };
    return <>
        <div className='tasks-page'>
            {(!!task?.questions?.length || !!task?.taskInEvents?.length) ? (
                <TaskCourseArticleComponent
                    {...other}
                    task={userIsAllowGenerateContent ? task : taskNotLogged}
                    event={event}
                    results={results}
                    result={resultState}
                    updateResults={updateResults}
                    setResult={setResultState}
                    user={user}
                    taskContent={taskContent}

                    handleEditTask={handleEditTask}
                    handleReplaceQuestion={handleReplaceQuestion}
                    handleEditVideoTask={handleEditVideoTask}
                    handleCheckResultAi={handleCheckResultAi}

                    highlightedRequest={highlightedRequest}
                    handleMouseUp={handleMouseUp}
                    setInProgressHighlight={setInProgressHighlight}
                    setHighlightedNode={setHighlightedNode}
                    setHighlightedRequest={setHighlightedRequest}
                    highlightedNode={highlightedNode}
                    inProgressHighlight={inProgressHighlight}
                    handlePlanGenerate={handlePlanGenerate}
                    handleDuplicateQuestion={handleDuplicateQuestion}
                    handleDeleteQuestion={handleDeleteQuestion}
                    updateTask={updateTask}
                    setInProgress={handleChangeProgress}
                    inProgress={inProgress}

                    stepId={stepId}
                    setStepId={setStepId}
                    onUpdateResultDescription={onUpdateResultDescription}
                    workspace={workspace}
                    questionStarted={questionStarted}
                    isQuestionProgress={isQuestionProgress}
                    isJoinRequired={isJoinRequired}
                    taskResult={taskResult}
                    tagsRenew={tagsRenew}
                    userIsAllowGenerateContent={userIsAllowGenerateContent}
                    progressCounter={progressCounter}
                />
            ) : (
                <TaskArticleComponent
                    {...other}
                    task={userIsAllowGenerateContent ? task : taskNotLogged}
                    userIsAllow={userIsAllowGenerateContent}
                    result={resultState}
                    updateResults={updateResults}
                    user={user}
                    highlightedRequest={highlightedRequest}
                    handleMouseUp={handleMouseUp}
                    handleEditTask={handleEditTask}
                    handleEditVideoTask={handleEditVideoTask}
                    handleCheckResultAi={handleCheckResultAi}
                    updateTask={updateTask}
                    handleReplaceQuestion={handleReplaceQuestion}
                    setInProgressHighlight={setInProgressHighlight}
                    inProgress={inProgress}
                    inProgressHighlight={inProgressHighlight}
                    stepId={stepId}
                    setStepId={setStepId}
                    workspace={workspace}
                    taskResult={taskResult}
                    tagsRenew={tagsRenew}
                />
            )}
        </div>
        <Modal
            title="Select Voice for AI videos"
            visible={isModalVisible}
            footer={null}
            closable={false}
            header={null}
            maskClosable={false}
        >
            <VoiceSelector
                voiceMode={voiceMode}
                defaultVoice={user?.defaultVoiceId}
                setVoiceMode={(selectedVoice) => {
                    setVoiceMode(selectedVoice);
                    setIsModalVisible(false);
                }}
            />
            <p className="text_secondary wrapper_content-section">
                Click on the "default" button to save your voice choice for future videos.
            </p>
        </Modal>
    </>
};
