import { useCallback } from 'react';
import { useAiChatResponse } from '../graphQL/useAiChatResponse';
import { useGetCommonAi } from './useGetCommonAi';
import { jsonrepair } from 'jsonrepair';
import { useGetCaptions } from '../../common/hooks/useGetCaptions';

const getEvent = async (arrayConcepts,
    goal,
    titleTrimmed,
    videoSet,
    sourceList,
    respFormat,
    getCaptions,
    captions) => {

    let tasks = [];
    for (const concept of arrayConcepts) {
        let cover = {};
        let galleryObjects = [];
        let questions = []

        if (concept?.module) {
            let video = videoSet?.find((el) => (el.videoId == concept?.id || el.source === concept?.id));
            let source = sourceList?.find((el) => el?.source?.includes(concept?.id)) ;
            if (concept?.id) {
                if (video?.source) {
                    cover = {
                        source: video.source,
                        summary: video.summary,
                        type: video.type,
                        thumbnail: video?.thumbnail,
                        title: video?.title,
                    }
                    if (!video?.raw) {
                        let raw = await getCaptions(video?.type, video?.videoId, cover)
                        video.raw = raw?.substring(0, 30000);    
                    }
                }
                if (source) {
                    let galleryObject = {
                        source: source.source,
                        type: source.type,
                        summary: source.summary,
                        timestamps: [],
                        title: source?.title,
                    }
                    galleryObjects.push(galleryObject)
                }
            }

            if (concept?.lessons) {          
                concept?.lessons.forEach((step, i) => {
                    let newQuestion = {
                        type: "TEXT",
                        question: '',
                        title: `## ${step?.title}\n${step?.outlines}`,
                        order: i,
                    };
                    questions.push(newQuestion);  
                })
            }

            let notFindGenerateAuto = sourceList?.find((el) => el?.key === 'yt_public') && !video;
            tasks.push({
                title: `${concept.module}`,
                ...({ source: `${respFormat || ''}\n\n${source?.raw || video?.raw || ''}` }),
                ...((source?.summary) && { summary: `${source?.summary || ''}` }),
                ...(!notFindGenerateAuto ? { isAutogenerated: false } : { isAutogenerated: true }),
                ...(!!video && { cover }),
                ...(galleryObjects && { galleries: galleryObjects }),
                ...(!!questions && { questions })

            });
        }
    };

    let galleriesInEvent = [];
    sourceList?.forEach((el) => {
        galleriesInEvent.push({
            gallery: {
                type: el?.type || '',
                summary: el?.summary || '',
                source: el?.source || '',
            }
        })
    })
    return {
        title: titleTrimmed || goal?.substring(0, 150),
        tasks,
        issue: goal,
        ...(!!sourceList?.length && { galleriesInEvent: galleriesInEvent })
    };
};

export const useGetCurrculumChannelSteps = () => {
    const { getChatResponse, error } = useAiChatResponse();
    const { getCommonAi } = useGetCommonAi();
    const { captions, captionsLoading, getCaptions } = useGetCaptions();

    const getCurriculumChannelSteps = useCallback(
        async (titles, goal, videoSet, sourceList) => {
            const titleTrimmed = goal?.length > 100 && await getCommonAi(goal?.substring(0, 1000), 'title');
            const respFormat = await getCommonAi(goal?.substring(0, 1000), 'format');
            const settings = {
                args: {
                    source: titles,
                    goal: goal,
                },
                type: 'curriculumStepsOutlines',
            };          
            const resp = await getChatResponse({
                variables: {
                    input: settings,
                },
            });

            const responseJSON = resp?.data ? JSON.parse(resp?.data?.requestChatApi) : null;
            const repaired = jsonrepair(responseJSON.choices[0].message.content.replace(/```json\n?|```/g, ''));
            try {
                const parsed = JSON.parse(repaired);
                if (parsed && parsed.curriculum && Array.isArray(parsed.curriculum)) {
                    let ev = await getEvent(parsed.curriculum, goal, titleTrimmed, videoSet, sourceList, respFormat, getCaptions, captions);
                    return ev;
                } else {
                    console.error('JSON does not have the expected structure');
                    return null;
                }
            } catch (e) {
                console.error('Failed to parse JSON:', e);
                return null;
            }

        },
        [getChatResponse]
    );

    return { getCurriculumChannelSteps };
};
