import React from 'react';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';

import { Button } from 'antd';
import { SketchP5Component } from '../../graph/SketchP5Component';
import { getTitle } from '../../common/utils';
import { VoiceSelector } from './VideoEditor.component/VoiceSelector'
import { recordCounterAIAss } from '../../../context/firebase/firebaseRequests';
import classnames from 'classnames';
import { openNotification } from '../../notification/Notification.component';
import { useTrackClick } from '../../common';

export const VideoCustomPlaceholder = ({ inProgress, task, handleGenerateVideo, user, userIsAllowGenerateContent, error, ...other }) => {
    const { formatMessage } = useIntl();
    const containerClass = classnames({
        'videoContainerStyle video_preview': true,
        'fullscreen': other.type === 'fullscreen'
    });

    const trackClick = useTrackClick();

    trackClick(
        'video_custom_placeholder',
        "example",
        window.location.href
    );
    return (
        <div className={containerClass}>
            <div className='sliderContainerStyle'>
                <div className=" slides_empty">
                    <SketchP5Component sketchIndex={2} />
                    <small className='slides_empty_message'>
                        {formatMessage({ id: 'video_desc' })}
                    </small>

                    <h2 className='slides_empty_message'>{inProgress ? inProgress?.message : getTitle(task.title)}</h2>
                    <VoiceSelector {...other} />
                    <div
                        className="circle-button"
                        type="primary"
                        shape="circle"
                        onClick={() => {
                            recordCounterAIAss(user?.id);
                            openNotification('topLeft', {
                                title: 'Coming soon, thank you for this request',
                                icon: '🙌',
                                duration: 3,
                            });

                            trackClick(
                                'video_ai_assistant_try',
                                "example",
                                window.location.href
                            );
                        }
                        }
                    >
                        <div className="circle-content">
                            <video className="circle-video" src="https://storage.googleapis.com/cdn.unschooler.me/web_app/tech_pictures/videos/string.mp4" autoPlay loop muted></video>
                            <span className="circle-text"><br />Add AI Avatar
                                <br /> <small>60 credits</small>
                            </span>
                        </div>
                    </div>
                    {!inProgress && <Button
                        className="wrapper_content-section"
                        onClick={() => handleGenerateVideo(null)}
                        disabled={!userIsAllowGenerateContent}
                        type="tertiary">{formatMessage({ id: 'video_btn' })}</Button>}

                    {error && <small className='slides_empty_message'>{error}<Link to={'/payments'}> {formatMessage({ id: 'payment_button' })}</Link></small>}

                </div>
            </div>
        </div>
    );
}